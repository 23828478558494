<template>
  <div>
  <!-- Table Container Card -->
  

      <div class="w-full" id="Scroll">
      <vx-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :max-items="itemsPerPage"
            :data="Model"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <div class="flex flex-wrap-reverse items-center">
                <span v-if="queriedItems > 0" class="mr-2 text-xl"
                  >{{ $t("Total") }} :
                </span>
                <span v-if="queriedItems > 0" class="text-primary text-xl">
                  {{ queriedItems }}
                </span>
              </div>
            </div>

            <template slot="thead">
              <vs-th >{{ $t("Tests & Reports") }}</vs-th>
              <vs-th >{{ $t("Upload Date") }}</vs-th>
              <vs-th >{{ $t("Description") }}</vs-th>
              <vs-th >{{ $t("Doctors") }}</vs-th>
              <vs-th>{{ $t("Actions") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      
                      {{ tr.ReportTypeName}}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-category">
                      <span :dir="$vs.rtl ? 'ltr' : 'ltr'">
                        {{ tr.Date.split("T")[0] }}
                    </span> 
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-category" :dir="$vs.rtl ? 'ltr' : 'ltr'">
                      {{ tr.Description }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <b-media vertical-align="center">
                      <template #aside>
                        <imageLazy
                      :imageStyle="{ 'border-radius': '15px','width': '30px','height':'30px'}"
                      :imageClass="'rounded-full'"
                      :src="baseURL + tr.DoctorImage"
                      placeHolderType="doctor"
                    />
                  </template>
                  <p class="font-weight-bold d-block text-nowrap">
            
                    {{( tr.DoctorName )}}
         
                  </p>
                </b-media>
                  </vs-td>
                  <vs-td>
                    <div class="text-nowrap">
        <!-- Dropdown -->
        <b-dropdown
          variant="link"
          toggle-class="p-0"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :href="baseURL + tr.AttachPath" download target="_blank" v-if="tr.AttachPath!=null&&tr.AttachPath!=undefined">
               
              <feather-icon icon="DownloadIcon" />
            <span class="align-middle ml-50">{{$t('Download')}}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="openConfirm(tr.item)">
            <feather-icon icon="TrashIcon"  />
            <span class="align-middle ml-50">{{$t('Delete')}}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>

                 
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </vx-card>
    </div>


    <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
import axios from "@/axios.js";
import imageLazy from "@/components/image_lazy.vue";
import { domain, mainSetting } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip } from 'bootstrap-vue';
export default {
  data() {
    return {
      tableColumns:[
         { key: 'TestsAndReports', label: this.$t("TestsAndReports"), sortable: true },
         { key: 'UploadDate', label: this.$t("UploadDate"), sortable: true },
         { key: 'Description', label: this.$t("Description"), sortable: true },
         { key: 'Doctors', label: this.$t("Doctors"), sortable: true },
         { key: 'Actions', label: this.$t("Actions") },
        
        ],
      itemsPerPage: 10,
      paginationPageNumber:1,
      paginationMaxNumber : 5,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      Model: [],
      ReportData: {},
      ShowDocumentUpload:false,
      documentModel:{},
      rowDataForDelete:{},
    };
  },
   computed: {
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.Model.length;
    },
    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
     ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
      ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
      openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert
      });
    },
     acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully")
      });
    },
     deleteData(data) {
      this.$store.dispatch("patientList/DeletePatientMedicalDocument", data).then(res=>{
        if(res.status==200){
          const ItemIndex = this.Model.findIndex(
        p => p.ID == data.ID && p.DocumentID == data.DocumentID
      );
        this.Model.splice(ItemIndex, 1);
        }
      }).catch(err => {
        console.error(err);
      });
    },
    OpenMedicalUploadDocument()
    {
      this.$emit("OpenMedicalUploadDocument",{});
    },

    getModel(){
    this.$store
      .dispatch("patientList/GetAllPatientMedicalReports", this.$store.state.AppActiveUser.Patient.ID)
      .then(res => {
        if (res.status == 200) {
          this.Model = res.data.Data;
              if(this.Model==null||this.Model.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        }
      })
      .catch(() => {
        window.showError();
      });
    },

    AddAttachmnet(){
      debugger
      this.documentModel.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      this.$store
      .dispatch("patientList/AddPatientMedicalDocument", this.documentModel)
      .then(res => {
        if (res.status == 200) {
          window.showSuccess(res.data.message);
          this.getModel();
        }
      })
      .catch((err) => {
     window.showError(err.response.data.message)
      });
      this.documentModel = {};
    },
    successUpload(event) {
      this.documentModel.AttachPath = event.target.responseText;
      window.showAddSuccess();
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then(response => {
          this.ReportData.attachPath = response.data;
        });
        this.ShowAddReportPopup = true;
      }
    }
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");
    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
      if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    // var Obj = {
    //   patientID: this.$store.state.AppActiveUser.Patient.ID
    // };
    this.getModel();
  },
  components:{
    imageLazy,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
  },
};
</script>

<style lang="scss">
.onlineDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
#page-user-list {
  .user-list-filters {
    height: 10px;

    .vs__actions {
      position: absolute;
      right: 0;
      top: 20%;
      height: 100px;
      transform: translateY(-58%);
    }
  }
}

#instant-search-demo {
  .header {
    .filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .price-slider {
    max-width: 80%;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #instant-search-demo {
    #content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4ab6a2;
  color: white;
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #content-container {
    .vs-sidebar {
      position: fixed !important;
      float: left !important;
    }
  }
}

.grid-view-item {
  // height: 450px;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }

  button.page-link {
    display: inline-block;
  }

  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }

  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}</style
>
<style>
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>

<!-- <style>
#patientMReport table {
 border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}
#patientMReport td,
#patientMReport th {
    border-collapse: collapse;
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
}

#patientMReport th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#patientMReport th:last-child {
  border-top-right-radius: 36px;
}


#patientMReport  .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
#patientMReport  .login-tabs-container {
  min-height: 505px;
}
#patientMReport  .con-tab {
  padding-bottom: 14px;
}

#patientMReport  .con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}
#patientMReport  .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
#patientMReport  .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}

</style> -->
